<template>
  <div>
    <div v-if="type == 'freetext'">
      <free-text-vocab-view
        :from="from"
        :to="to"
        :original="original"
        :correctAnswer="correctOption"
        @done="done"
        @check="check"
      ></free-text-vocab-view>
    </div>
    <div v-if="type == 'multichoice'">
      <multiple-choice-vocab-view
        :from="from"
        :to="to"
        :original="original"
        :options="options"
        :correctOption="correctOption"
        @done="done"
      ></multiple-choice-vocab-view>
    </div>
    <div v-if="type == 'newword'">
      <h1>
        <strong>{{ navi }}</strong> - {{ english }}
      </h1>
      <p>Enter the Na'vi below to continue:</p>
      <free-text
        special-characters
        v-model="answer"
        :state="textboxState"
      ></free-text>
    </div>
  </div>
</template>

<style scoped>
/*stylesheet*/
</style>

<script>
import MultipleChoiceVocabView from "./components/MultipleChoice.vue";
import FreeTextVocabView from "./components/FreeText.vue";
import FreeText from "@/components/input/FreeText.vue";
export default {
  name: "LessonTestVocab",
  components: {
    MultipleChoiceVocabView,
    FreeTextVocabView,
    FreeText
  },
  props: {
    data: Object
  },
  data: function() {
    return {
      slug: "testvocab",
      type: null,
      from: null,
      to: null,
      original: null,
      options: null,
      correctOption: null,
      navi: null,
      english: null,
      answer: "",
      textboxState: undefined,
      freetextState: undefined
    };
  },
  methods: {
    communicate(message) {
      this.$emit("message", message);
    },
    done(answer) {
      if (this.type == "multichoice") {
        // multichoice events return an index, so resolve to a word
        answer = this.options[answer];
      }
      this.communicate({
        answer: answer,
        submit: true
      });
    },
    check(answer) {
      if (this.type == "multichoice") {
        // multichoice events return an index, so resolve to a word
        answer = this.options[answer];
      }
      this.communicate({
        answer: answer,
        submit: false
      });
    }
  },
  computed: {
    incomingDataCounter: function() {
      return this.data._counter;
    }
  },
  watch: {
    incomingDataCounter: function() {
      if (this.data.info == "lesson.newword") {
        this.type = "";
        this.textboxState = undefined;
        this.answer = "";
        this.english = this.data.english;
        this.navi = this.data.navi;
        this.type = "newword";
      } else if (this.data.info == "lesson.activity") {
        this.type = "";
        this.from = this.data.from;
        this.to = this.data.to;
        this.correctOption = null;
        this.original = this.data.original;
        if (this.data.type == "multiplechoice") {
          this.options = this.data.options;
          this.type = "multichoice";
        } else if (this.data.type == "freetext") {
          this.type = "freetext";
        }
      } else if (this.data.info == "lesson.markactivity") {
        if (this.type == "multichoice") {
          this.correctOption = this.options.indexOf(this.data.answer);
        } else if (this.type == "freetext") {
          this.correctOption = this.data.answer;
        }
      }
    },
    answer: function() {
      if (this.answer == this.navi) {
        this.textboxState = "correct";
        this.communicate({
          continue: true
        });
      }
    }
  },
  mounted() {
    this.$emit("onready", this.slug);
  }
};
</script>
