import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Lesson from "../views/Lesson.vue";

import LessonTest from "../views/lessons/Test.vue";
import LessonTestVocab from "../views/lessons/TestVocabulary.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/lesson",
    component: Lesson,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "test",
        component: LessonTest
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "testvocab",
        component: LessonTestVocab
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
