<template>
  <!-- free text field, that can also include the special Na'vi characters -->
  <v-form class="maxwidth" @submit="nosubmit">
    <v-text-field
      v-model="text"
      :label="label"
      filled
      id="textfield"
      @input="fireInputEvent()"
      @keypress="checkForEnter"
      :color="color"
      :background-color="backgroundcolor"
      :loading="state == 'loading'"
      :readonly="state != 'none'"
    ></v-text-field>
    <!-- Only displayed if specialCharacters arg is True -->
    <div class="center" v-if="specialCharacters">
      <v-btn outlined rounded small @click="addChar('ì')">ì</v-btn>&nbsp;
      <v-btn outlined rounded small @click="addChar('ä')">ä</v-btn>&nbsp;
      <v-btn outlined rounded small @click="addChar('\'')">'</v-btn>
    </div>
  </v-form>
</template>

<style scoped>
.center {
  margin: auto;
  text-align: center;
}

.maxwidth {
  max-width: 980px;
}
</style>

<script>
export default {
  name: "FreeText",
  components: {},
  props: {
    label: {
      type: String,
      default: "Enter translation here..."
    },
    specialCharacters: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    },
    state: {
      type: String,
      default: "none"
    }
  },
  data: function() {
    return {
      text: ""
    };
  },
  methods: {
    addChar(char) {
      var element = document.getElementById("textfield");
      element.focus();
      this.text += char;
      this.fireInputEvent();
    },
    fireInputEvent() {
      this.$emit("input", this.text);
    },
    checkForEnter(event) {
      if (event.keyCode == 13) {
        // if the key pressed was enter
        this.$emit("submit", this.text);
      }
    },
    nosubmit(event) {
      event.preventDefault();
    }
  },
  watch: {
    value: function() {
      this.text = this.value;
    }
  },
  computed: {
    color: function() {
      if (this.state == "correct") {
        return "success";
      } else if (this.state == "incorrect") {
        return "warning";
      } else {
        return undefined;
      }
    },
    backgroundcolor: function() {
      if (this.state == "correct") {
        return "light-green-lighten-4";
      } else if (this.state == "incorrect") {
        return "orange-lighten-4";
      } else {
        return undefined;
      }
    }
  },
  mounted() {
    this.text = this.value;
  }
};
</script>
