<template>
  <!-- lesson card - for letting users select a lesson -->
  <v-card :class="'size-' + size">
    <v-img
      :src="image"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      :height="sizeNum"
    >
      <v-app-bar flat color="rgba(0,0,0,0)" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer v-if="icon != 'none'"></v-spacer>
        <v-icon color="white" v-if="icon != 'none'">mdi-{{ icon }}</v-icon>
      </v-app-bar>
    </v-img>

    <v-card-text class="text--primary">
      <div>{{ description }}</div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn elevation="0">{{ buttonText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>

<script>
export default {
  name: "card",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    image: {
      type: String,
      default: "https://source.unsplash.com/random"
    },
    buttonText: {
      type: String,
      default: "Continue"
    },
    size: {
      type: String,
      default: "large"
    },
    icon: {
      type: String,
      default: "none"
    }
  },
  computed: {
    sizeNum: function() {
      if (this.size == "small") return "64px";
      else if (this.size == "medium") return "100px";
      else if (this.size == "large") return "200px";
      return "64px";
    }
  }
};
</script>
