<template>
  <!-- home page, lists all lessons -->
  <div class="home">
    <router-view
      @onready="setupWs"
      @message="wsMessageSend"
      :data="data"
      v-if="done == false"
    />
    <div v-if="done">
      <center>
        <h1>Nice work!</h1>
        <h3>Completed in {{ duration }} seconds!</h3>
      </center>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import Card from "@/components/Card.vue";

export default {
  name: "Lesson",
  components: {},
  data: function() {
    return {
      ws: null,
      done: false,
      start: -1,
      end: -1,
      duration: -1,
      data: {
        _counter: 0,
        data: {
          _counter: 0
        }
      }
    };
  },
  methods: {
    setupWs(slug) {
      this.ws = new WebSocket("wss://ws.nvapi.tk/lesson/create/" + slug);
      this.ws.onmessage = this.wsMessageReceived;
    },
    wsMessageReceived(event) {
      var d = JSON.parse(event.data);
      var infoArr = d.info.split(".");
      var isInternal = infoArr[0] == "internal";
      if (!isInternal) {
        var newData = d.payload;
        newData._counter = this.data._counter + 1; // tells the component whether an update has occured
        newData.info = d.info;
        this.data = newData;
      } else {
        if (infoArr[1] == "done") {
          this.start = d.payload.start;
          this.end = d.payload.end;
          this.duration = d.payload.duration;
          this.done = true;
        }
      }
    },
    wsMessageSend(message) {
      this.ws.send(
        JSON.stringify({
          info: "lesson.message",
          status: 200,
          payload: message
        })
      );
    }
  }
};
</script>
