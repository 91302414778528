<template>
  <!-- shows a list of buttons, and allows the program to mark one as correct once
    the user made a selection -->
  <v-container class="maxwidth">
    <h1>{{ original }}</h1>
    <h2>
      Select the <strong>{{ to }}</strong> for this {{ from }}
      {{ originalType }}
    </h2>
    <multiple-choice
      :options="options"
      :correctOption="correctOption"
      @input="submit"
    ></multiple-choice>
  </v-container>
</template>

<style scoped>
.center {
  margin: auto;
  text-align: center;
}

.maxwidth {
  max-width: 980px;
}
</style>

<script>
import MultipleChoice from "@/components/input/MultipleChoice.vue";
export default {
  name: "MultipleChoiceVocabView",
  components: {
    MultipleChoice
  },
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    original: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    correctOption: {
      type: Number,
      default: null
    }
  },
  data: function() {
    return {
      chosenOption: null
    };
  },
  methods: {
    submit(event) {
      this.$emit("done", event);
    }
  },
  computed: {
    originalType: function() {
      if (this.original.includes(" ")) return "phrase";
      else return "word";
    }
  }
};
</script>
