<template>
  <div>
    <free-text
      from="English"
      to="Na'vi"
      original="Bread"
      @done="dothing"
      @check="check"
    ></free-text>
    <multiple-choice
      from="Na'vi"
      to="English"
      original="Zola'u níprrte'"
      :options="['a', 'b']"
      @done="dothing"
    ></multiple-choice>
  </div>
</template>

<style scoped>
/*stylesheet*/
</style>

<script>
import MultipleChoice from "./components/MultipleChoice.vue";
import FreeText from "./components/FreeText.vue";
export default {
  name: "LessonTest",
  components: {
    MultipleChoice,
    FreeText
  },
  props: {
    data: Object
  },
  data: function() {
    return {
      slug: "test"
    };
  },
  methods: {
    communicate(message) {
      this.$emit("message", message);
    },
    dothing(event) {
      alert("done " + event);
    },
    check(event) {
      alert("check " + event);
    }
  },
  computed: {
    incomingDataCounter: function() {
      return this.data._counter;
    }
  },
  watch: {
    incomingDataCounter: function() {
      this.communicate({ success: true });
    }
  },
  mounted() {
    this.$emit("onready", this.slug);
  }
};
</script>
