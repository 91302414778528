<template>
  <!-- home page, lists all lessons -->
  <div class="home">
    <!-- This container contains suggested lessons -->
    <v-container class="grey lighten-5">
      <v-row>
        <v-col cols="12" sm="4">
          <!-- 1/3rd width -->
          <card
            :title="recommendedLesson['title']"
            :description="recommendedLesson['description']"
            :icon="recommendedLesson['completed'] ? 'beenhere' : 'assistant'"
            :buttonText="
              recommendedLesson['completed'] ? 'Retry lesson' : 'Begin lesson'
            "
            size="large"
          ></card>
        </v-col>
        <v-col cols="12" sm="4">
          <card
            :title="randomLesson['title']"
            :description="randomLesson['description']"
            :buttonText="
              randomLesson['completed'] ? 'Retry lesson' : 'Begin lesson'
            "
            icon="shuffle"
            size="large"
          ></card>
        </v-col>
      </v-row>
    </v-container>
    <!-- this container contains all lessons -->
    <v-container class="grey lighten-5">
      <v-row>
        <v-col
          v-for="lesson in lessons"
          :key="lesson['title'] + lesson['description']"
          cols="12"
          sm="3"
        >
          <card
            :title="lesson['title']"
            :description="lesson['description']"
            :icon="lesson['completed'] ? 'head-check' : 'none'"
            :buttonText="lesson['completed'] ? 'Retry lesson' : 'Begin lesson'"
            size="medium"
          ></card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Card from "@/components/Card.vue";

export default {
  name: "Home",
  components: {
    Card
  },
  data: function() {
    return {
      lessons: [
        {
          title: "Lesson 1",
          description: "This will teach you one thing",
          completed: false
        },
        {
          title: "Lesson 2",
          description: "This will teach you two things",
          completed: true
        },
        {
          title: "Lesson 3",
          description: "This will teach you three things",
          completed: false
        },
        {
          title: "Lesson 4",
          description: "This will teach you four things",
          completed: false
        }
      ],
      recommendedLesson: {
        title: "Recommended lesson",
        description: "This will teach you recommended things",
        completed: false
      }
    };
  },
  computed: {
    randomLesson: function() {
      var selected = { completed: false };
      while (!selected["completed"]) {
        selected = this.lessons[
          Math.floor(Math.random() * this.lessons.length)
        ];
      }
      return selected;
    }
  }
};
</script>
