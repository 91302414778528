<template>
  <!-- Main app template - pulls all components together -->
  <v-app id="app">
    <!-- Always shows a header, even in smaller screens. -->
    <v-app-bar app color="blue" hide-on-scroll dark>
      <v-toolbar-title>{{ appName }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="mdl-layout__content">
      <v-container fluid class="page-content">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>
export default {
  data: function() {
    return {
      appName: "Na'vigator"
    };
  }
};
</script>
