<template>
  <!-- shows a list of buttons, and allows the program to mark one as correct once
    the user made a selection -->
  <v-container class="maxwidth">
    <v-row>
      <v-col
        v-for="option in options"
        :key="option + options.indexOf(option)"
        cols="12"
        lg="4"
        sm="6"
      >
        <v-btn
          block
          :elevation="determineElevation(options.indexOf(option))"
          x-large
          :color="determineColor(options.indexOf(option))"
          :disabled="disableButtons"
          @click="selectionMade(options.indexOf(option))"
          >{{ option }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.center {
  margin: auto;
  text-align: center;
}

.maxwidth {
  max-width: 980px;
}
</style>

<script>
export default {
  name: "MultipleChoice",
  components: {},
  props: {
    label: {
      type: String,
      default: "Select an option..."
    },
    options: {
      type: Array,
      required: true
    },
    correctOption: {
      type: Number,
      default: null
    },
    value: {
      type: Number,
      default: null
    }
  },
  data: function() {
    return {
      chosenOption: null
    };
  },
  methods: {
    determineColor(index) {
      if (this.correctOption != null) {
        // if user has made selection and...
        if (this.correctOption == index) {
          return "success"; // this option is the correct answer
        } else if (this.chosenOption == index) {
          return "warning"; // this option was selected, but is wrong
        } else {
          return "disabled"; // this option was not selected and is unmarked or incorrect
        }
      }
      return "primary"; // user has made no selection
    },
    determineElevation(index) {
      // determines how much shadow a button should have - higher = more
      if (index == this.chosenOption) {
        if (this.correctOption != null) {
          return 6; // this option selected and marked
        }
        return 24; // this option selected, unmarked
      } else if (this.chosenOption != null) {
        if (this.correctOption == index) {
          return 6; // user selected a different option, but this option is correct
        }
        return 0; // user selected a different option
      } else {
        return 6; // user has made no selection
      }
    },
    selectionMade(index) {
      if (this.chosenOption != null) return;
      this.chosenOption = index;
      this.$emit("input", this.chosenOption);
    }
  },
  computed: {
    disableButtons: function() {
      return this.chosenOption != null && this.correctOption == null;
    }
  },
  watch: {
    correctOption: function() {
      if (this.correctOption == null) {
        this.chosenOption = null;
      }
    }
  }
};
</script>
