<template>
  <!-- Shows a word, asks for a translation -->
  <v-container class="maxwidth">
    <h1>{{ original }}</h1>
    <h2>
      Enter the <strong>{{ to }}</strong> for this {{ from }}
      {{ originalType }}
    </h2>
    <free-text
      v-model="text"
      :label="'Enter answer in ' + to"
      :state="state"
      :specialCharacters="to == 'Na\'vi'"
      @input="check"
      @submit="submit"
    ></free-text>
    <div v-if="correctAnswer != null && state == 'incorrect'">
      <h1>
        Correct answer: <strong>{{ correctAnswer }}</strong>
      </h1>
    </div>
  </v-container>
</template>

<style scoped>
.center {
  margin: auto;
  text-align: center;
}

.maxwidth {
  max-width: 980px;
}
</style>

<script>
import FreeText from "@/components/input/FreeText.vue";
export default {
  name: "FreeTextVocabView",
  components: {
    FreeText
  },
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    original: {
      type: String,
      required: true
    },
    correctAnswer: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      text: "",
      waiting: false
    };
  },
  methods: {
    submit(event) {
      this.$emit("done", event);
      this.waiting = true;
    },
    check(event) {
      this.$emit("check", event);
    }
  },
  computed: {
    originalType: function() {
      if (this.original.includes(" ")) return "phrase";
      else return "word";
    },
    state: function() {
      if (this.correctAnswer == this.text) {
        return "correct";
      } else if (this.correctAnswer != null) {
        return "incorrect";
      } else if (this.waiting) {
        return "loading";
      } else {
        return "none";
      }
    }
  },
  watch: {
    original: function() {
      this.text = "";
      this.waiting = false;
    }
  }
};
</script>
